import React, { useState, createContext, useContext } from "react";
import PropTypes from "prop-types";
import { mergeTerms } from "./configMergeUtils";
import { BannerType } from "../banner/Banner";

export const ConfigContext = createContext({
  landerConfig: {},
  queryConfig: {},
  mergedConfig: {},
  isAdsenseSuccess: null,
  setIsAdsenseSuccess: () => {},
});

export const getTypeID = (type) => {
  if (type === BannerType.AUCTION) return "auctions";
  if (type === BannerType.DBS) return "broker";
  return "other";
};

export const getBannerTypeAndURL = (landerConfig) => {
  const type = ((landerConfig?.lander || {}).banner || {}).type;
  const link = ((landerConfig?.lander || {}).banner || {}).link;
  return { type, url: new URL(link) };
};

export const getCampaignID = (inTreatmentCohort, type) => {
  const cohortID = inTreatmentCohort ? "b" : "a";
  return `x_dom-${getTypeID(type)}_parkedpages_x_x_invest_${cohortID}_001`;
};

export const getChannelID = (inTreatmentCohort, landerConfig) => {
  return inTreatmentCohort
    ? (landerConfig.adSense.channel += "+treatment")
    : (landerConfig.adSense.channel += "+control");
};

export const getExperimentBannerLink = (inTreatmentCohort, landerConfig) => {
  const { type, url } = getBannerTypeAndURL(landerConfig);
  url.searchParams.set("utm_campaign", getCampaignID(inTreatmentCohort, type));
  return decodeURIComponent(url.href);
};

export const useLanderConfig = () => {
  return useContext(ConfigContext);
};

export const LanderConfigProvider = ({
  landerConfig,
  queryConfig,
  children = void 0,
}) => {
  const [isAdsenseSuccess, setIsAdsenseSuccess] = useState(true);
  const inTreatmentCohort = landerConfig?.experiment?.bucket === "pw_treatment";
  if (
    landerConfig?.experiment?.key === "parking_parkweb_ab_02" &&
    !isAdsenseSuccess
  ) {
    landerConfig.lander.banner.link = getExperimentBannerLink(
      inTreatmentCohort,
      landerConfig
    );
    landerConfig.adSense.channel = getChannelID(
      inTreatmentCohort,
      landerConfig
    );
  }

  const mergedConfig = {
    keywords: mergeTerms(landerConfig, queryConfig),
  };

  const contextValue = {
    landerConfig: landerConfig,
    queryConfig: queryConfig,
    mergedConfig: mergedConfig,
    isAdsenseSuccess,
    setIsAdsenseSuccess,
  };

  return (
    <ConfigContext.Provider value={ contextValue }>
      {children}
    </ConfigContext.Provider>
  );
};
LanderConfigProvider.propTypes = {
  landerConfig: PropTypes.object.isRequired,
  queryConfig: PropTypes.object.isRequired,
  children: PropTypes.node,
};
