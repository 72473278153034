import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { EventType, QueryParam } from "../commons/Constants";
import postEvent, { createBaseLanderEvent } from "../commons/LanderEvent";
import Logger from "../commons/Logger";
import { useLanderConfig } from "../commons/ConfigContext";
import { Settings } from "../config";
import { VerizonContent } from "../verizon/VerizonContent";
import { AdSense } from "./utils/adSenseUtils";
import loadAdsense from "./utils/loadAdsense";

function AdSenseBlock({ landerConfig, page, queryConfig }) {
  let adsenseContentDivId = "ads";
  if (page === AdSense.page.RELATED_LINKS) {
    adsenseContentDivId = "relatedLinks";
  }

  let search;
  if (
    !landerConfig.lander.template.includes("PARK_WEB") &&
    queryConfig[QueryParam.SEARCH_BOX]
  ) {
    search = (
      <div id="searchbox">
        <FormattedMessage id="contentBlockedText" />
      </div>
    );
  }

  return (
    <>
      <div id={ adsenseContentDivId }>
        <FormattedMessage id="contentBlockedText" />
      </div>
      {search}
    </>
  );
}

AdSenseBlock.propTypes = {
  page: PropTypes.string.isRequired,
  queryConfig: PropTypes.object.isRequired,
  landerConfig: PropTypes.object.isRequired,
};

export function AdContent({ landerConfig, queryConfig, page }) {
  const { isAdsenseSuccess, setIsAdsenseSuccess } = useLanderConfig();

  useEffect(() => {
    loadAdsense(landerConfig, queryConfig, page, setIsAdsenseSuccess);
  }, [landerConfig, page, queryConfig, setIsAdsenseSuccess]);

  const blockAdsense = landerConfig.isForcedVerizon || !isAdsenseSuccess;

  if (blockAdsense) {
    const inTreatmentCohort =
      landerConfig?.experiment?.bucket === "pw_treatment";
    if (inTreatmentCohort) {
      try {
        sendEvent(landerConfig);
        window.location.assign(landerConfig.lander?.banner?.link);
      } catch (e) {
        Logger.error(
          "Missing valid redirect to SERP from" +
            landerConfig.domain.rootDomain +
            toString(e)
        );
      }
    } else {
      return <VerizonContent />;
    }
  } else if (!blockAdsense)
    return (
      <AdSenseBlock
        landerConfig={ landerConfig }
        queryConfig={ queryConfig }
        page={ page }
      />
    );

  return <FormattedMessage id="contentBlockedText" />;
}

AdContent.propTypes = {
  page: PropTypes.string.isRequired,
  landerConfig: PropTypes.object.isRequired,
  queryConfig: PropTypes.object.isRequired,
};

function sendEvent(landerConfig) {
  const serpRedirectEvent = createBaseLanderEvent(
    EventType.SERP_REDIRECT,
    landerConfig
  );
  serpRedirectEvent.bannerLink = landerConfig.lander?.banner?.link ?? "no-link";
  postEvent(Settings.EVENT_PUBLISH_API, serpRedirectEvent);
}
